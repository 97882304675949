import './App.css';
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom"
import ContactUs from './views/contactus';
import Policy from './views/policy';
import AccountDeletion from './views/accountdeletion';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/" element={<AccountDeletion />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;