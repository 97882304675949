// import logo from './logo.svg';
import '../App.css';

function Policy() {
    return (
        <div className="App">
        <body className="App-body">
            <h1 style={{ color: "black", alignSelf: 'flex-start', marginLeft: 30 }}>Privacy Policy</h1>
            <div style={{
                color: "CaptionText", flexDirection: 'row', justifyContent: 'flex-start', 
            }}>
                <p style={{ color: "black", marginLeft: 30 }}>
                At My Prayers App, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our devotional prayer mobile application. Please read this Privacy Policy carefully. By using the App, you agree to the terms of this Privacy Policy.
                    Information We Collect
                    We may collect certain information from you when you download and use the App. This includes:
                    1. Personal Information: We may collect personal information such as your name, email address, and any other information you voluntarily provide to us.
                    2. Device Information: We may collect information about the mobile device you use to access the App, including the device unique identifier, operating system, and mobile network information.
                    3. Usage Information: We may collect information about how you use the App, including your interaction with the content, features, and functionalities of the App.
                    How We Use Your Information
                    We may use the information we collect from you for various purposes, including:
                    1. To provide and maintain the App functionality and features.
                    2. To personalize your experience and deliver tailored content and recommendations.
                    3. To communicate with you, including responding to your inquiries, providing customer support, and sending relevant notifications.
                    4. To improve the App and develop new features and services.
                    Data Security
                    We prioritize the security of your information and have implemented appropriate technical and organizational measures to protect it. However, please note that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                    Third-Party Services
                    We may engage third-party service providers to assist us with various aspects of our app. These third parties may have access to your information only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose.
                    Updates to This Privacy Policy
                    We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective immediately upon posting the updated Privacy Policy in the App. Your continued use of the App after any modifications to the Privacy Policy will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
                    
                    Contact Us:
                    If you have any questions or concerns regarding this Privacy Policy, please contact us at . 

                    Email: <href>aprayer122023@gmail.com</href>
                    Contact number:  <href>7407243757</href>

                    Thank you for using My Prayers App, and we hope you enjoy your devotional prayer experience!
                    Please note that this is just a sample, and it is always recommended to consult with legal professionals to ensure compliance with applicable laws and regulations.
                
                </p>
            </div>
        </body>

    </div>
    );
}

export default Policy;

