import React, { useState } from 'react';

const AccountDeletion = () => {
    const [userId, setUserId] = useState('');
    const [remarks, setRemarks] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://theseedapp.com/api/user/user-delete', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId, remarks })
            });
            const data = await response.json();
            if (data.status) {
                setMessage('Your account has been deleted successfully.');
            } else {
                setMessage('There was an error deleting your account.');
            }
        } catch (error) {
            console.error('Error:', error);
            setMessage('There was an error deleting your account.');
        }
    };

    return (
        <div>
            <h1>Delete Your Account</h1>
            <p>If you want to delete your account, please fill out the form below:</p>
            <form onSubmit={handleSubmit}>
                <label htmlFor="userId">User ID:</label>
                <input
                    type="number"
                    id="userId"
                    name="userId"
                    value={userId}
                    onChange={(e) => setUserId(e.target.value)}
                    required
                /><br /><br />
                <label htmlFor="remarks">Remarks:</label>
                <input
                    type="text"
                    id="remarks"
                    name="remarks"
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                /><br /><br />
                <button type="submit">Delete Account</button>
            </form>
            <p>Note: Once your account is deleted, it cannot be recovered.</p>
            {message && <p>{message}</p>}
        </div>
    );
};

export default AccountDeletion;