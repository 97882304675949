import '../App.css';

function ContactUs() {
    return (
        <div className="App">
            <body className="App-body">
                <h1 style={{ color: "black", alignSelf: 'flex-start', marginLeft: 30 }}>Contact Us</h1>
                <div style={{
                    color: "CaptionText", flexDirection: 'row', justifyContent: 'flex-start', whiteSpace: 'nowrap',
                    display: 'inline',
                }}>
                    <h4 style={{ color: "black", alignSelf: 'flex-start', marginLeft: 30 }}>Email : aprayer122023@gmail.com</h4>
                    <h4 style={{ color: "black", alignSelf: 'flex-start', marginLeft: 30 }}>Contact number : 7407243757 </h4>
                </div>
            </body>

        </div>
    );
}

export default ContactUs;

